@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:ital,wght@0,200..800;1,200..800&display=swap');

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
  font-family: 'Plus Jakarta Sans', sans-serif;
}
.swiper-button-next,
.swiper-button-prev {
  padding: 0 5px;
  background-color: #21409a;
  border-radius: 20px;
  height: 30px !important;
}

.swiper-button-next:after,
.swiper-button-prev:after {
  color: white;
  font-size: 15px !important;
}

@media only screen and (max-width: 1791px) {
  .rabbit_1 {
    height: 160px;
    right: 10%;
  }
  .rabbit_2 {
    height: 200px;
  }
}

@media only screen and (max-width: 1400px) {
  /* .form_details {
    top: 11%;
    right: 1%;
  } */
  .rabbit_1 {
    height: 150px;
    right: 10%;
    margin-top: 24px;
  }
  .rabbit_2 {
    height: 150px;
    right: 7%;
    margin-top: 24px;
  }
  .rabbit_3 {
    height: 350px;
    right: 4%;
    margin-top: 24px;
  }
}

@media only screen and (max-width: 1023px) {
  .rabbit_3 {
    display: none;
  }
}
